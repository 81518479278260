
import { apiGet } from '../utils/http';

export async function requestCityList(params = {}) {
  const api = 'book/city/getCityList';
  return await apiGet(api, params);
}

export async function requestCityHighList(params = {}) {
  const api = 'book/city/getCityHighList';
  return await apiGet(api, params);
}

export async function requestCityLandmarkList(params = {}) {
  const api = 'book/city/getLandmarkList';
  return await apiGet(api, params);
}

export async function requestGeolocation(params = {}) {
  const api = 'book/city';
  return await apiGet(api, params);
}

export async function locateCity(params = {}) {
  const api = 'book/city/locateCity';
  return await apiGet(api, params);
}

export async function requestLocateByKeyword(params = {}) {
  const api = 'book/city/keyword';
  return await apiGet(api, params);
}
// 全地标搜索
export async function requestLandmarkSearch(params = {}) {
  const api = 'book/landmark/search';
  return await apiGet(api, params);
}
